<template>
    <LoadedContent class="project" :loaded="projectLoaded" :error="projectError">
        <h1 >{{ project.name }}</h1>
        <LoadedContent :loaded="isInfoLoaded" :error="isInfoError">
            <section class="info-boxes" v-if="hasExtension('cms_base_1') && hasPermission('moderateProject')">
                <main>
                    <div class="info-block menu-items" >
                        <h3>{{ $t('controls.menu_items') }}</h3>
                        <div class="number">{{ getExtensionInfo('cms_base_1','menu_item_count') }}</div>
                    </div>
                    <div class="info-block content-pages">
                        <h3>{{ $t('titles.content_pages') }}</h3>
                        <div class="number">{{  getExtensionInfo('cms_base_1','page_count') }}</div>
                    </div>
                    <div class="info-block contents">
                        <h3>{{ $t('titles.content_blocks') }}</h3>
                        <div class="number">{{  getExtensionInfo('cms_base_1','content_count') }}</div>
                    </div>
                </main>
            </section>
            <section class="info-boxes" v-if="hasExtension('enrollment_1')">
                <header>
                    <h2>{{ $t('enrollment.application_count') }}</h2>
                </header>
                <main>
                    <div class="info-block" v-for="(school,index) in infoSchools" :key="index">
                        <h3>{{ school.short_name }}</h3>
                        <div class="number">{{ school.applicationsCount.all }}</div>
                    </div>
                </main>
            </section>
            <section class="info-boxes" v-if="hasExtension('enrollment_1')">
                <header>
                    <h2>{{ $t('enrollment.application_accepted_count') }}</h2>
                </header>
                <main>
                    <div class="info-block success" v-for="(school,index) in infoSchools" :key="index">
                        <h3>{{ school.short_name }}</h3>
                        <div class="number">{{ school.applicationsCount.accepted }}</div>
                    </div>
                </main>
            </section>
            <section class="info-boxes" v-if="hasExtension('webur_1')">
                <header>
                    <h2>{{ $t('titles.budget_view') }}</h2>
                </header>
                <main>
                    
                    <RouterLinkLocal :link="'url.project/:project_code/url.budget'">
                    <div class="info-block success">
                        <h3>{{ $t('controls.got_to_module')}}</h3>
                        <div class="number">></div>
                    </div>
                    </RouterLinkLocal>
                    
                </main>
            </section>
        </LoadedContent>
    </LoadedContent>
</template>

<script>
import useProject from '@admin/composable/ProjectModel';
import LoadedContent from '@common/components/base/LoadedContent'
import { computed, watchEffect } from 'vue';
import { useStore } from 'vuex';

export default {
    name: "Project",
    components: {
        LoadedContent,
    },
    setup(){
        const { 
            projectLoaded,
            projectError,
            project,
            hasExtension,
            hasPermission
        } = useProject(); 

        const store = useStore();
        watchEffect(() => {
            if(projectLoaded.value){
                store.dispatch('project/fetchInfo');
            }
        })
        const info = computed(() => store.getters['project/getInfo']);

        const isInfoLoaded = computed(() => store.getters["project/isInfoLoaded"]);
        
        const isInfoError = computed(() => store.getters["project/isInfoError"]);

        const getExtensionInfo = (extension,info_key) => {
            if(!info.value || !info.value.extensions){
                return {}
            }
            let ext_info = {};
            for(let extension_info of info.value.extensions){
                if(extension_info.item_key == extension){
                    if(extension_info.info && typeof extension_info.info === 'object' && Object.prototype.hasOwnProperty.call(extension_info.info,info_key)){
                        ext_info = extension_info.info[info_key]
                    }
                    break;
                }
            }
            return ext_info;
        }

        const infoSchools = computed(() => getExtensionInfo("enrollment_1","schools"))

        return {
            projectLoaded,
            projectError,
            project,
            hasExtension,
            hasPermission,
            info,
            isInfoLoaded,
            isInfoError,
            infoSchools,
            getExtensionInfo
        }
    }

}
</script>

<style scoped>
.info-boxes{
    margin: 1rem;
    border-bottom: 1px solid grey;
}
.info-boxes h2{
    margin: 0.5rem;
}
.info-block{
    background: rgb(134 193 241);
    box-shadow: 0 0 10px -2px grey;
    float: left;
    margin: 1rem;
    width: 12rem;
    height: 6rem;
    color: #094575;
    position: relative;
    border: 1px solid #a8b7c3;
}
.info-block h3{
    margin: 0.5rem;
    border-bottom: 1px solid white;
    text-shadow: 1px 1px 5px white;
}
.info-block .number{
    position: absolute;
    font-size: 3.5rem;
    font-weight: 1000;
    right: 0.5rem;
    bottom: 0rem;
    text-shadow: 1px 1px 15px white;
    line-height: 4rem;
}
.info-block.success{
    background: #7dd6a6;
    color: #0b541e;
}
/* .project h2{
    font-size: 1.4rem; 
    margin: 0;
    padding: 0.3rem 1rem;
    background: #e9fff3;
    box-shadow: 0px 0px 5px 1px #177542;
} */
</style>